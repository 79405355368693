import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const HeaderContainer = styled.header`
  background: #000;
  margin-bottom: 1.45rem;
  position: fixed;
  z-index: 500;
  display: flex;
  min-height: 55px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
  a {
    color: #fff;
    text-decoration: none;
  }
`

const Header = ({ siteTitle }) => (
  <HeaderContainer>
    <div>
      <h4 style={{ margin: 0 }}>
        <Link to="/">{siteTitle}</Link>
      </h4>
    </div>
  </HeaderContainer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
