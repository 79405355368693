import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
// import Nav from '../components/sidebar'
import './layout.css'
import './sidebar.css'
import './nav.css'
import { MotionNav } from '../components/MotionNav'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <MotionNav />
        {/* <Nav right /> */}
        <div>
          <main>{children}</main>
        </div>
        <footer
          style={{
            background: `#000`,
            color: `#fff`,
            minHeight: `40px`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `flex-end`,
            padding: `0 20px`,
          }}
        >
          ©{new Date().getFullYear()} MSD Web Solutions
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
